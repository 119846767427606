import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I've been using `}<a parentName="p" {...{
        "href": "https://fly.io/"
      }}>{`Fly.io`}</a>{` to deploy my Elixir API and Postgres database.`}</p>
    <p>{`These are my notes on helpful commands. Most of these are for debugging purposes.`}</p>
    <p>{`To view the logs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ fly logs -a <app-name>
`}</code></pre>
    <p>{`To deploy with no cache:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ fly deploy  -a <api-app-name> --remote-only --no-cache
`}</code></pre>
    <p>{`To "rollback" a Fly deployment to a previous version:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ fly releases --image -a <api-app-name>
$ fly deploy  -a <api-app-name> -i registry.fly.io/your-app-name@sha256-hash
`}</code></pre>
    <p>{`To connect to DB remotely:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ fly ssh console -a <db-app-name>
$ psql <DATABASE_URL>
`}</code></pre>
    <p>{`To restart the DB:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ fly postgres restart -a <db-app-name>
`}</code></pre>
    <p>{`To restart the API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ fly apps restart <api-app-name>
`}</code></pre>
    <p>{`To restore a database snapshot:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ fly volumes list -a <db-app-name>

$ fly volumes snapshots list <volume-id>

# scale down
$ fly scale count 0 -a <api-app-name>
 #start back up
$ fly scale count 1 -a <api-app-name>
$ fly deploy --remote-only -a <api-app-name>

$ fly postgres detach <db-app-name>

$ fly postgres attach <db-backup-app-name>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      